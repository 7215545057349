import React from "react";
import logo from "../assets/images/logo-3.png";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="bg-primary flex flex-col">
      <div className="relative w-full px-[4.7rem] py-10 bg-primary">
        <div className="ml-2 mr-2 mx-auto flex footer-container  flex-row justify-between text-white md:gap-6 gap-2">
          <div className="sr-only md:not-sr-only flex flex-col grow gap-3 items-start">
            <div className="flex mb-5 items-end select-none self-start">
              <Link to="/" className="text-2xl">
                <img
                  src={logo}
                  alt="Mereb Technologies logo - Software Development and Outsourcing"
                  className="w-80"
                />
              </Link>
            </div>
            <Link
              to="/privacy"
              className="font-heading font-semibold text-slate-100 hover:text-gray-400 transition-colors cursor-pointer text-center md:text-left"
            >
              {t("footer.privacy")}
            </Link>
          </div>
          <div className="flex flex-col grow items-start md:items-start gap-1">
            <h2 className="flex font-semibold font-heading mb-4 text-2xl">
              <div className="relative w-1 mr-3 bg-gray-200">
                <div className="absolute -translate-y-1/2 bg-indigo-950/95 animate-bounce-big w-full h-2"></div>
              </div>
              {t("footer.services.title")}
            </h2>
            {[
              "softwareDevelopment",
              "talentOutsourcing",
              "customSolution",
              "maintenanceSupport",
            ].map((service, index) => (
              <span
                key={index}
                className="my-[0.2rem] font-body transition-colors cursor-default md:text-left w-full whitespace-nowrap"
              >
                {t(`footer.services.${service}`)}
              </span>
            ))}
          </div>
          <div className="flex flex-col grow items-start md:items-start gap-2">
            <h2 className="flex font-semibold font-heading mt-5 md:mt-0 mb-4 text-2xl">
              <div className="relative w-1 mr-3 bg-gray-200">
                <div className="absolute -translate-y-1/2 bg-indigo-950/95 animate-bounce-big w-full h-2"></div>
              </div>
              {t("footer.contact")}
            </h2>
            <div className="flex md:flex-row justify-start md:gap-9 w-full md:text-left flex-wrap">
              <ContactInfo
                location="Sheridan, Wyoming, USA"
                address="30 N Gould St, Ste R, Sheridan, WY 82801"
                phone="+1(307)-206-9767"
                email=""
              />
              <ContactInfo
                location="Addis Ababa, Ethiopia"
                address="Noah Complex, Bole, 10th Floor"
                phone=""
                email="info@mereb.tech"
              />
              <ComingSoon location="UAE" />
            </div>
            <SocialLinks />
          </div>
          <div className="md:sr-only flex flex-col items-center grow-2">
            <div className="my-6 select-none">
              <img src={logo} alt="Mereb Technologies Logo" className="w-52" />
            </div>
          </div>
        </div>
      </div>
      <p className="text-center text-gray-300 py-1 mb-2">
        <span className="text-white">Mereb Technologies</span> © 2024{" "}
        {t("footer.rights")}.
      </p>
    </div>
  );
}

const ContactInfo = ({ location, address, phone, email }) => (
  <div className="mb-4 ">
    <p className="pb-2 flex items-center">
      <span className="font-bold pr-2">
        <CiLocationOn className="inline-block" />
      </span>
      <span className="flex-1">{location}</span>
    </p>
    <p className="pb-[5px] pl-6">
      <span className="font-bold">{address}</span>
    </p>
    {phone && (
      <p className="pb-2 flex items-center pl-1">
        <span className="font-bold pr-2">
          <FaPhoneAlt className="inline-block" />
        </span>
        <a href={`tel:${phone}`}>{phone}</a>
      </p>
    )}
    {email && (
      <p className="pb-2 flex items-center pl-1">
        <span className="font-bold pr-2">
          <MdEmail className="inline-block" />
        </span>
        <a href={`mailto:${email}`}>{email}</a>
      </p>
    )}
  </div>
);

const ComingSoon = ({ location }) => (
  <div className="md:pl-[3px]">
    <p>
      <span className="font-bold pr-2">
        <CiLocationOn className="inline-block" />
      </span>
      {location}
    </p>
    <div className="coming-soon-container">
      <span className="font-bold pr-6"></span>
      <span className="coming-soon-text">{t("footer.comingSoon")}</span>
      <div className="coming-soon-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
);

const SocialLinks = () => (
  <div className="flex flex-row items-center justify-center md:justify-start gap-2">
    {[
      {
        href: "https://www.linkedin.com/company/mereb-technologies",
        icon: <FaLinkedin className="w-7 h-7 cursor-pointer text-primary" />,
      },
      {
        href: "https://www.facebook.com/techmereb/",
        icon: <FaFacebookF className="w-6 h-6 cursor-pointer text-primary" />,
      },
      {
        href: "https://twitter.com/merebtech",
        icon: <FaXTwitter className="w-7 h-7 cursor-pointer text-primary" />,
      },
      // {
      //   href: "https://www.instagram.com/merebtech/?hl=en",
      //   icon: <FaInstagram className="w-7 h-7 cursor-pointer text-primary" />,
      // },

      // {
      //   href: "https://wa.me/+251916144128",
      //   icon: <FaWhatsapp className="w-7 h-7 cursor-pointer text-primary" />,
      // },
    ].map((link, index) => (
      <a
        key={index}
        href={link.href}
        aria-label={link.href}
        target="_blank"
        rel="noreferrer"
        className="rounded-full w-8 h-8 flex p-1.5 items-center justify-center bg-white"
      >
        {link.icon}
      </a>
    ))}
  </div>
);

export default Footer;
