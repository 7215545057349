import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import logo from "../assets/images/logo-3.png";
import bg1 from "../assets/MEREB_GTEX_WEB_FILES/Background_Slider.jpg";

import team from "../assets/MEREB_GTEX_WEB_FILES/gtx-1.jpeg";

// Hook for navbar effect
import useNavbarEffect from "../hooks/useNavbarEffect";

function Modal({ children, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full p-6 mx-4 relative">
        <button
          className="absolute top-4 right-4 text-red-600 hover:text-red-800  hover:bg-red-200 rounded-full p-2 transition duration-200 md:text-xl"
          onClick={onClose}
          aria-label="Close"
        >
          ✖
        </button>
        {children}
      </div>
    </div>
  );
}

export default function GTXPolish() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
      fullName: "",
      email: "",
      message: "",
    });
    const [errors, setErrors] = useState({});
  
    const [submitted, setSubmitted] = useState(false);
    const [response, setResponse] = useState("");
    const form = useRef();
  
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === "from_name") {
        setFormData((prev) => ({ ...prev, fullName: value }));
      }
      if (name === "reply_to") {
        setFormData((prev) => ({ ...prev, email: value }));
      }
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
  
    const onSubmit = (e) => {
      setResponse("");
      setSubmitted(false);
      setErrors({});
      e.preventDefault();
  
      if (!handleValidation()) return;
      setSubmitted(true);
  
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          formData,
          process.env.REACT_APP_USER_ID
        )
        .then(
          () => {
            setResponse("Successfully submitted!");
            setSubmitted(false);
          },
          () => {
            setResponse("Error while submitting!");
            setSubmitted(false);
          }
        )
        .catch((err) => {
          console.error(err);
          setResponse("Error while submitting!");
          setSubmitted(false);
        })
        .finally(() => {
          setFormData({
            fullName: "",
            email: "",
            message: "",
          });
          setTimeout(() => {
            setIsModalOpen(false);
            setResponse("");
          }, 5000);
        });
    };
  
    function handleValidation() {
      const fields = formData;
      const errs = {};
      let formIsValid = true;
      if (!fields.fullName) {
        formIsValid = false;
        errs.fullName = "Name cannot be empty";
      }
      if (!fields.email) {
        formIsValid = false;
        errs.email = "E-mail cannot be empty";
        const isvalid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.email
        );
        if (!isvalid) {
          formIsValid = false;
          errs.email = "Please enter a valid e-mail address";
        }
      }
      if (!fields.message) {
        formIsValid = false;
        errs.message = "Message cannot be empty";
      }
  
      setErrors(errs);
      return formIsValid;
    }
  
    useNavbarEffect(true, false);
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return (
      <div
        className="pt-4 bg-gradient-to-r from-blue-500 to-blue-600 h-fit"
        style={{
          backgroundImage: `url(${bg1})`,
        }}
      >
        <nav className="flex justify-between items-center px-1 py-2  max-w-5xl mx-auto">
          <img
          onClick={() => window.location.href = "/"}
            src={logo}
            alt="Mereb Technologies Logo"
            className="w-20 md:w-40 cursor-pointer"
          />
        </nav>
        <section className="text-white py-16 pt-8 px-4 md:px-2 rounded-lg mb-16 flex flex-col lg:flex-row items-center lg:justify-between max-w-5xl mx-auto">
          <div className="text-center lg:text-left lg:max-w-lg">
            <h1 className="text-5xl font-black">
              Struggling to Find Skilled Talent? We’ve Got You Covered!
            </h1>
  
            <p className="text-xl leading-relaxed mb-1 mt-4">
              Poland’s outsourcing industry is thriving, but finding top-notch
              talent isn’t easy. That’s where we come in.
            </p>
            
            <ul className="text-md mb-6 text-blue-200 list-none list-inside">
                <li className="flex items-start mb-1">
                    <span className="text-green-500 mr-2">✅</span>
                    Skilled developers in game and software development.
                </li>
                <li className="flex items-start mb-1">
                    <span className="text-green-500 mr-2">✅</span>
                    Affordable, flexible subcontracting options to boost your capacity.
                </li>
                <li className="flex items-start mb-1">
                    <span className="text-green-500 mr-2">✅</span>
                    Seamless integration with your team for faster results.
                </li>
            </ul>
            <p className="text-md mb-6 text-blue-200">
              Partner with us to overcome the talent gap and focus on delivering
              exceptional results to your clients.
              <br />
              📈 Let’s scale your operations without compromising on quality or
              cost.
            </p>
  
            <div className="flex justify-center lg:justify-start w-fit mx-auto lg:mx-0 rounded-full overflow-hidden border-2 border-blue-500 transition duration-300 focus:border-blue-400 bg-transparent">
              <button
                onClick={handleOpenModal}
                className="bg-gradient-to-l from-blue-400 to-blue-500 hover:bg-blue-400 text-white font-bold px-6 py-2 rounded-full transition duration-300"
              >
                Contact us today to discover how we can support your projects with world-class talent!
              </button>
            </div>
          </div>
  
          {/* Right-side image (kept for layout consistency) */}
          <div className="mt-8 lg:mt-0 w-full lg:w-auto flex justify-center lg:justify-end relative">
  <div className="absolute inset-0 transform rotate-3 bg-blue-200 bg-opacity-30 rounded-lg shadow-xl w-full lg:w-96"></div>
  <img
    src={team}
    alt="Team"
    className="relative rounded-lg shadow-xl w-full lg:w-96 object-cover border-3 border-blue-500 border-opacity-50 transform -rotate-3"
  />
</div>
        </section>
        {/* Let’s Talk Tech Section */}
        <section className="text-white py-12 md:pt-28 px-8 max-w-6xl mx-auto flex flex-col space-y-8">
          <div className="flex flex-col md:flex-row md:justify-between items-center md:items-start space-y-8 md:space-y-0 text-left">
            <div className="flex-1 text-center md:text-left">
              <h3 className="text-4xl font-extrabold mb-4">Let’s Talk Tech!</h3>
              <p className="text-lg mb-4">
                If you’re interested in learning more about how Mereb can help you
                modernize your legacy software—without starting from scratch—we’d
                love to connect.
              </p>
            </div>
  
            {/* Vertical Divider for Larger Screens */}
            <div className="hidden md:block h-32 w-px bg-white mx-8"></div>
  
            {/* Contact Information */}
            <div className="flex flex-1 flex-col md:flex-row md:space-x-12 items-center md:items-start text-center md:text-left space-y-8 md:space-y-0">
              <div>
                <p className="font-semibold">Sheridan, Wyoming, USA</p>
                <p>30 N Gould St, Ste R, Sheridan, WY 82801</p>
                <p>
                  Phone:{" "}
                  <a href="tel:+13072069767" className="hover:underline">
                    +1(307)-206-9767
                  </a>
                </p>
              </div>
              <div>
                <p className="font-semibold">Addis Ababa, Ethiopia</p>
                <p>Bole, Airport Road</p>
                <p>
                  Phone:{" "}
                  <a href="tel:+251916144128" className="hover:underline">
                    +251916144128
                  </a>{" "}
                  /{" "}
                  <a href="tel:+251939346647" className="hover:underline">
                    +251939346647
                  </a>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:info@mereb.tech" className="hover:underline">
                    info@mereb.tech
                  </a>
                </p>
              </div>
            </div>
          </div>
  
          <div className="flex flex-col md:flex-row justify-between items-center mt-16 text-center md:text-left space-y-4 md:space-y-0">
            <p className="text-sm">All rights reserved (2024)</p>
            <img
              src={logo}
              alt="Mereb Technologies Logo"
              className="w-32 md:w-52 mx-auto md:mx-0"
            />
          </div>
        </section>
        {isModalOpen && (
          <Modal onClose={handleCloseModal}>
            <form
              id="subscription-form"
              ref={form}
              onSubmit={onSubmit}
              className=" px-6   h-full py-6 mx-auto subscription-form "
            >
              <p className="text-gray-600 mb-6">
                Fill out the form below and we’ll get back to you shortly.
              </p>
  
              <div className="mb-4">
                <textarea
                  name="message"
                  cols="30"
                  rows="4"
                  className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                  placeholder="Please enter your message here..."
                  disabled={submitted}
                  value={formData.message}
                  onChange={(e) => handleInputChange(e)}
                />
                <p
                  className={`text-sm mt-1 ${
                    errors.message ? "text-red-500" : "text-transparent"
                  }`}
                >
                  {errors.message}
                </p>
              </div>
  
              <div className="flex flex-col md:flex-row mb-4 w-full gap-3">
                <div className="flex flex-col w-full md:w-1/2">
                  <input
                    name="from_name"
                    type="text"
                    className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                    placeholder="Full Name"
                    disabled={submitted}
                    value={formData.fullName}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <p
                    className={`text-sm mt-1 ${
                      errors.fullName ? "text-red-500" : "text-transparent"
                    }`}
                  >
                    {errors.fullName}
                  </p>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                  <input
                    name="reply_to"
                    type="email"
                    className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                    placeholder="Email Address"
                    disabled={submitted}
                    value={formData.email}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <p
                    className={`text-sm mt-1 ${
                      errors.email ? "text-red-500" : "text-transparent"
                    }`}
                  >
                    {errors.email}
                  </p>
                </div>
              </div>
  
              <div className="text-center">
                <button
                  type="submit"
                  className="px-6 py-3 text-lg font-semibold text-white bg-primaryMedium rounded-md hover:bg-blue-100 hover:text-black transition-colors duration-300 w-full"
                  disabled={submitted}
                >
                  {submitted ? "Submitting..." : "Submit"}
                </button>
                <p
                  className={`${
                    response.includes("Successfully")
                      ? `text-green-500 mt-2`
                      : `text-red-500 mt-2`
                  }`}
                >
                  {response && response.includes("Successfully")
                    ? "Successfully submitted!"
                    : response.includes("Error")
                    ? "Error while submitting!"
                    : ""}
                </p>
              </div>
            </form>
          </Modal>
        )}
      </div>
    );
  }