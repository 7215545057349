import React, { useEffect } from "react";
import HrImage from "../assets/teams/management/mik_hr.png";
import tabs from "../assets/images/tabs.svg";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import useNavbarEffect from "../hooks/useNavbarEffect";
import { CommonHeader } from "../components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TECH_TALENT_POOL } from "../constants/urls";
import { useTranslation } from "react-i18next";

function JobApplicationPage() {
  useNavbarEffect(true, false);
  const [miniScreen, setMiniScreen] = React.useState(false);
  const [onePerSlide, setOnePerSlide] = React.useState(false);
  const { t } = useTranslation();

  const jobsData = [
    {
      id: 13,
      title: t("jobApplications.jobListings.jobApplicationThirteen.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationThirteen.description"
      ),
      date: "12/11/2024",
    },
    {
      id: 12,
      title: t("jobApplications.jobListings.jobApplicationTwelve.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationTwelve.description"
      ),
      date: "12/11/2024",
    },
    {
      id: 2,
      title: t("jobApplications.jobListings.jobApplicationEight.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationEight.description"
      ),
      date: "12/11/2024",
    },
    {
      id: 10,
      title: t("jobApplications.jobListings.jobApplicationOne.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationOne.description"
      ),
      date: "11/20/2024",
    },
    {
      id: 11,
      title: t("jobApplications.jobListings.jobApplicationTwo.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationTwo.description"
      ),
      date: "11/20/2024",
    },
    {
      id: 1,
      title: t("jobApplications.jobListings.jobApplicationThree.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationThree.description"
      ),
      date: "11/12/2024",
    },
    {
      id: 3,
      title: t("jobApplications.jobListings.jobApplicationFour.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationFour.description"
      ),
      date: "9/17/2024",
    },
    {
      id: 4,
      title: t("jobApplications.jobListings.jobApplicationFive.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationFive.description"
      ),
      date: "9/17/2024",
    },
    {
      id: 5,
      title: t("jobApplications.jobListings.jobApplicationSix.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationSix.description"
      ),
      date: "9/17/2024",
    },
    {
      id: 9,
      title: t("jobApplications.jobListings.jobApplicationSeven.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationSeven.description"
      ),
      date: "9/3/2024",
    },

    {
      id: 6,
      title: t("jobApplications.jobListings.jobApplicationNine.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationNine.description"
      ),
      date: "",
    },
    {
      id: 7,
      title: t("jobApplications.jobListings.jobApplicationTen.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationTen.description"
      ),
      date: "",
    },
    {
      id: 8,
      title: t("jobApplications.jobListings.jobApplicationEleven.title"),
      description: t(
        "jobApplications.jobListings.jobApplicationEleven.description"
      ),
      date: "",
    },
  ];
  useEffect(() => {
    const handleResize = () => {
      setOnePerSlide(window.innerWidth <= 1025);
      setMiniScreen(window.innerWidth <= 1324);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function formatTimeDifference(pastDate, currentDate) {
    const diffInSeconds = Math.floor((currentDate - pastDate) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} second${diffInSeconds !== 1 ? "s" : ""} ${t(
        "jobApplications.jobListings.dateSuffix"
      )}`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ${t(
        "jobApplications.jobListings.dateSuffix"
      )}`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ${t(
        "jobApplications.jobListings.dateSuffix"
      )}`;
    } else if (diffInDays < 30) {
      return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ${t(
        "jobApplications.jobListings.dateSuffix"
      )}`;
    } else {
      const diffInMonths = Math.floor(diffInDays / 30);
      return `${diffInMonths} month${diffInMonths !== 1 ? "s" : ""} ${t(
        "jobApplications.jobListings.dateSuffix"
      )}`;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    margin: 10,
    slidesToScroll: 3,
    infinite: true,
    autoplay: true,
    nextArrow: miniScreen ? null : (
      <div>
        <div className="next-slick-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="black"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" />
          </svg>
        </div>
      </div>
    ),

    prevArrow: miniScreen ? null : (
      <div>
        <div className="next-slick-arrow rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="black"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" />
          </svg>
        </div>
      </div>
    ),
    autoplaySpeed: 1090000,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <CommonHeader
      title={t("jobApplications.title")}
      subTitle={t("jobApplications.subTitle")}
      src={tabs}
      alt={"Tabs illustration"}
    >
      <div className="">
        <div className="container mx-auto px-4 py-16">
          <div className="animate-magic bg-gradient-to-r from-primary to-primaryMedium text-white p-8 rounded-lg shadow-lg mb-10 relative overflow-hidden">
            <div className="text-4xl font-bold mb-4 inline items-center">
              {t("jobApplications.heroSection.titleSectionOne")}
            </div>
            <span className="inline-block animate-wave mx-2 text-4xl font-bold">
              👋
            </span>
            <div className="text-4xl font-bold mb-4 inline items-center">
              {t("jobApplications.heroSection.titleSectionTwo")}
            </div>

            <p className="text-lg mb-6 leading-relaxed">
              {t("jobApplications.heroSection.description")}
            </p>
            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
              <button
                onClick={() =>
                  window.open(TECH_TALENT_POOL, "_blank", "noopener,noreferrer")
                }
                className="bg-white text-primary px-6 py-3 rounded-full font-semibold shadow-md hover:bg-primary hover:text-white transition-transform transform hover:scale-105"
              >
                {t("jobApplications.heroSection.buttonText")}
              </button>
            </div>
            <div className="absolute -top-5 -left-5 w-20 h-20 bg-white opacity-10 rounded-full"></div>
            <div className="absolute -bottom-5 -right-5 w-24 h-24 bg-white opacity-10 rounded-full"></div>
          </div>

          <div className="flex flex-col md:flex-row items-start mb-16 bg-white p-6 rounded-lg ">
            <div className="md:w-2/3 p-4">
              <h2 className="text-4xl font-bold mb-4">
                {t("jobApplications.whyJoin.titleSectionOne")}{" "}
                <span className="text-primaryMedium">
                  {t("jobApplications.whyJoin.titleSectionTwo")}
                </span>
              </h2>
              <ul className="list-disc pl-5 space-y-4 text-gray-700">
                <li>
                  <strong>
                    {t("jobApplications.whyJoin.reasonOne.title")}:
                  </strong>{" "}
                  {t("jobApplications.whyJoin.reasonOne.description")}
                </li>
                <li>
                  <strong>
                    {t("jobApplications.whyJoin.reasonTwo.title")}:
                  </strong>{" "}
                  {t("jobApplications.whyJoin.reasonTwo.description")}
                </li>
                <li>
                  <strong>
                    {t("jobApplications.whyJoin.reasonThree.title")}:
                  </strong>{" "}
                  {t("jobApplications.whyJoin.reasonThree.description")}
                </li>
                <li>
                  <strong>
                    {t("jobApplications.whyJoin.reasonFour.title")}:
                  </strong>{" "}
                  {t("jobApplications.whyJoin.reasonFour.description")}
                </li>
                <li>
                  <strong>
                    {t("jobApplications.whyJoin.reasonFive.title")}:
                  </strong>{" "}
                  {t("jobApplications.whyJoin.reasonFive.description")}
                </li>
              </ul>
            </div>

            <div className="md:w-[30%] p-6 flex flex-col items-center bg-gradient-to-br from-white to-gray-100 border border-gray-200 rounded-1xl shadow-sm hover:shadow-1xl transform transition-all duration-300">
              <div className="relative w-48 h-48 mb-4">
                <img
                  src={HrImage}
                  alt="HR Contact"
                  className="w-full h-full rounded-full object-contain border-4 border-white shadow-md"
                />
                <div className="absolute inset-0 bg-black bg-opacity-10 rounded-full"></div>
              </div>
              <div className="text-center">
                <h3 className="text-2xl font-bold text-gray-800 mb-2 tracking-wide">
                  Mickiyas Kinfemichael
                </h3>
                <p className="text-lg font-medium text-gray-500 mb-4">
                  {t("jobApplications.recruiter.position")}
                </p>
                <div className="text-lg text-gray-600 space-y-2 text-left">
                  <p>
                    <MdEmail className="inline-block mr-2 text-blue-900" />
                    <a
                      href="mailto:hr@merebtechnologies.com"
                      className="text-blue-900 hover:text-blue-700 transition-colors duration-200"
                    >
                      career@mereb.tech
                    </a>
                  </p>
                  <a href="tel:+251988259995">
                    <FaPhoneAlt className="inline-block mr-2 text-blue-900" />
                    <span className="text-gray-600">+251988259995</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[100%] ">
            <Slider {...sliderSettings}>
              {jobsData.map((job) => (
                <div
                  key={job.id}
                  className={`${
                    onePerSlide ? "w-full" : "max-w-[400px]"
                  } card-cou h-[350px] my-11 bg-gradient-to-r from-primary to-primaryMedium text-white p-6 rounded-lg shadow-xl transform transition-all hover:scale-105 hover:shadow-2xl flex flex-col justify-between`}
                >
                  {/* Content Section */}
                  <div className="flex-grow">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-2xl font-extrabold tracking-wide">
                        {job.title}
                      </h3>
                      <span className="inline-block px-3 whitespace-nowrap py-1 bg-white text-indigo-500 text-xs font-semibold rounded-full shadow-sm">
                        {t("jobApplications.jobListings.card.label")}
                      </span>
                    </div>
                    <p className="text-gray-100 mb-6 leading-relaxed">
                      {job.description}
                    </p>
                  </div>

                  <div className="mt-auto flex items-center justify-between">
                    <Link
                      to={`/jobs/${job.id}`}
                      state={{ title: job.title }}
                      className="bg-white text-gray-900 px-5 py-2 rounded-full font-semibold shadow-md hover:bg-primary hover:text-white transition-all transform hover:-translate-y-1"
                    >
                      {t("jobApplications.jobListings.card.buttonText")}
                    </Link>
                    <span className="text-sm italic opacity-75">
                      {job.date &&
                        formatTimeDifference(new Date(job.date), new Date())}
                    </span>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </CommonHeader>
  );
}

export default JobApplicationPage;
