import React, { useLayoutEffect } from "react";
import ServiceCard from "./ServiceCard";
import s3 from "../assets/images/s33.webp";
import s4 from "../assets/images/s44.webp";
import s11 from "../assets/images/s111.webp";
import s22 from "../assets/images/s22.webp";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".service");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((ele) => {
        if (ele.isIntersecting) {
          ele.target.classList.add("animate-moveup");
        }
      });
    });
    cards.forEach((card) => {
      observer.observe(card);
    });
    return () => {
      cards.forEach((card) => {
        observer.disconnect(card);
      });
    };
  }, []);
  const contents = [s22, s11,  s4];
  const contentDetails = [
    {
      title: t("services.softwareDevelopment.title"),
      image: s22,
      description: t("services.softwareDevelopment.description"),
    },
    {
      title: t("services.talentOutsourcing.title"),
      image: s11,
      description: t("services.talentOutsourcing.description"),
    },

    // {
    //   title: t("services.customSolution.title"),
    //   image: s3,
    //   description: t("services.customSolution.description"),
    // },
    {
      title: t("services.maintenanceSupport.title"),
      image: s4,
      description: t("services.maintenanceSupport.description"),
    },
  ];

  return (
    <section className="lg:px-20 my-16 md:my-16">
      <div className="container mx-auto">
        <div className="text-center mb-10">
          <h4 className="text-primary  text-2xl md:text-4xl mt-1 font-heading font-bold flex items-center justify-center">
            {t("services.title")}
          </h4>
          <p className="text-gray-700 max-w-3xl mx-auto"></p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 md:gap-8">
          {contents.map((x, i) => (
            <ServiceCard
              key={i}
              index={i + 1}
              image={x}
              content={contentDetails[i]}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
