import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

// Images and assets (keep or remove as needed)
// import neb_img from "../assets/MEREB_GTEX_WEB_FILES/Nebyou.png";
// import yar_img from "../assets/MEREB_GTEX_WEB_FILES/Yared.png";
// import sam_img from "../assets/MEREB_GTEX_WEB_FILES/Samuel.png";
// import pocker from "../assets/MEREB_GTEX_WEB_FILES/Poker.jpg";
// import nft from "../assets/MEREB_GTEX_WEB_FILES/NFT.jpg";
import logo from "../assets/images/logo-3.png";
import bg1 from "../assets/MEREB_GTEX_WEB_FILES/Background_Slider.jpg";
// import bg2 from "../assets/MEREB_GTEX_WEB_FILES/Background_midsection.jpg";
import team from "../assets/MEREB_GTEX_WEB_FILES/gtx-2.jpeg";
// import lightIcon from "../assets/MEREB_GTEX_WEB_FILES/Icons/Inovvative.svg";
// import topTier from "../assets/MEREB_GTEX_WEB_FILES/Icons/talent.svg";
// import client from "../assets/MEREB_GTEX_WEB_FILES/Icons/Client_Centric.svg";

// Hook for navbar effect
import useNavbarEffect from "../hooks/useNavbarEffect";

function Modal({ children, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full p-6 mx-4 relative">
        <button
          className="absolute top-4 right-4 text-red-600 hover:text-red-800  hover:bg-red-200 rounded-full p-2 transition duration-200 md:text-xl"
          onClick={onClose}
          aria-label="Close"
        >
          ✖
        </button>
        {children}
      </div>
    </div>
  );
}

export default function GTXPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState("");
  const form = useRef();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "from_name") {
      setFormData((prev) => ({ ...prev, fullName: value }));
    }
    if (name === "reply_to") {
      setFormData((prev) => ({ ...prev, email: value }));
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = (e) => {
    setResponse("");
    setSubmitted(false);
    setErrors({});
    e.preventDefault();

    if (!handleValidation()) return;
    setSubmitted(true);

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_USER_ID
      )
      .then(
        () => {
          setResponse("Successfully submitted!");
          setSubmitted(false);
        },
        () => {
          setResponse("Error while submitting!");
          setSubmitted(false);
        }
      )
      .catch((err) => {
        console.error(err);
        setResponse("Error while submitting!");
        setSubmitted(false);
      })
      .finally(() => {
        setFormData({
          fullName: "",
          email: "",
          message: "",
        });
        setTimeout(() => {
          setIsModalOpen(false);
          setResponse("");
        }, 5000);
      });
  };

  function handleValidation() {
    const fields = formData;
    const errs = {};
    let formIsValid = true;
    if (!fields.fullName) {
      formIsValid = false;
      errs.fullName = "Name cannot be empty";
    }
    if (!fields.email) {
      formIsValid = false;
      errs.email = "E-mail cannot be empty";
      const isvalid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        formData.email
      );
      if (!isvalid) {
        formIsValid = false;
        errs.email = "Please enter a valid e-mail address";
      }
    }
    if (!fields.message) {
      formIsValid = false;
      errs.message = "Message cannot be empty";
    }

    setErrors(errs);
    return formIsValid;
  }

  useNavbarEffect(true, false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="pt-4 bg-gradient-to-r from-blue-500 to-blue-600 h-fit"
      style={{
        backgroundImage: `url(${bg1})`,
      }}
    >
       <nav className="flex justify-between items-center px-1 py-2  max-w-5xl mx-auto">
          <img
          onClick={() => window.location.href = "/"}
            src={logo}
            alt="Mereb Technologies Logo"
            className="w-20 md:w-40 cursor-pointer"
          />
        </nav>
      {/* Intro Section */}
      <section className="text-white py-16 pt-8 px-4 md:px-2 rounded-lg mb-16 flex flex-col lg:flex-row items-center lg:justify-between max-w-5xl mx-auto">
        <div className="text-center lg:text-left lg:max-w-lg">
          {/* First Headline */}
          <h1 className="text-5xl font-black">
            Modernize Your Legacy Software – Upgrade Without Disruption!
          </h1>
          <p className="text-xl leading-relaxed mb-1 mt-4">
            Your current software has potential—it just needs an upgrade! At
            Mereb Technology, we specialize in:
          </p>
          <ul className="text-md mb-6 text-blue-200 list-disc list-inside">
            <li>Enhancing your existing systems for modern efficiency</li>
            <li>Improving scalability and performance</li>
            <li>Reducing downtime and maintenance costs</li>
          </ul>
          <p className="text-md mb-6 text-blue-200">
            <strong>
              Experience a seamless transformation with our Free One-Month
              Trial!
            </strong>
            <br />
            Let us help you unlock the full potential of your software while
            keeping your business running smoothly.
          </p>

          {/* Form and CTA button */}
          <div className="flex justify-center lg:justify-start w-fit mx-auto lg:mx-0 rounded-full overflow-hidden border-2 border-blue-500 transition duration-300 focus:border-blue-400 bg-transparent">
            {/* <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email Address"
              className="px-4 py-2 w-64 rounded-l-full text-white bg-transparent outline-none"
            /> */}
            <button
              onClick={handleOpenModal}
              className="bg-gradient-to-l from-blue-400 to-blue-500 hover:bg-blue-400 text-white font-bold px-6 py-2 rounded-full transition duration-300"
            >
              Start Your Free Trial Today
            </button>
          </div>
        </div>


        <div className="mt-8 lg:mt-0 w-full lg:w-auto flex justify-center lg:justify-end relative">
  <div className="absolute inset-0 transform rotate-3 bg-blue-200 bg-opacity-30 rounded-lg shadow-xl w-full lg:w-96"></div>
  <img
    src={team}
    alt="Team"
    className="relative rounded-lg shadow-xl w-full lg:w-96 object-cover border-3 border-blue-500 border-opacity-50 transform -rotate-3"
  />
</div>
              </section>

              {/* Second Headline Section */}

      {/* Let’s Talk Tech Section */}
      <section className="text-white py-12 md:pt-28 px-8 max-w-6xl mx-auto flex flex-col space-y-8">
        <div className="flex flex-col md:flex-row md:justify-between items-center md:items-start space-y-8 md:space-y-0 text-left">
          <div className="flex-1 text-center md:text-left">
            <h3 className="text-4xl font-extrabold mb-4">Let’s Talk Tech!</h3>
            <p className="text-lg mb-4">
              If you’re interested in learning more about how Mereb can help you
              modernize your legacy software—without starting from scratch—we’d
              love to connect.
            </p>
          </div>

          {/* Vertical Divider for Larger Screens */}
          <div className="hidden md:block h-32 w-px bg-white mx-8"></div>

          {/* Contact Information */}
          <div className="flex flex-1 flex-col md:flex-row md:space-x-12 items-center md:items-start text-center md:text-left space-y-8 md:space-y-0">
            <div>
              <p className="font-semibold">Sheridan, Wyoming, USA</p>
              <p>30 N Gould St, Ste R, Sheridan, WY 82801</p>
              <p>
                Phone:{" "}
                <a href="tel:+13072069767" className="hover:underline">
                  +1(307)-206-9767
                </a>
              </p>
            </div>
            <div>
              <p className="font-semibold">Addis Ababa, Ethiopia</p>
              <p>Bole, Airport Road</p>
              <p>
                Phone:{" "}
                <a href="tel:+251916144128" className="hover:underline">
                  +251916144128
                </a>{" "}
                /{" "}
                <a href="tel:+251939346647" className="hover:underline">
                  +251939346647
                </a>
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@mereb.tech" className="hover:underline">
                  info@mereb.tech
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mt-16 text-center md:text-left space-y-4 md:space-y-0">
          <p className="text-sm">All rights reserved (2024)</p>
          <img
            src={logo}
            alt="Mereb Technologies Logo"
            className="w-32 md:w-52 mx-auto md:mx-0"
          />
        </div>
      </section>
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <form
            id="subscription-form"
            ref={form}
            onSubmit={onSubmit}
            className=" px-6   h-full py-6 mx-auto subscription-form "
          >
            {/* <h2 className="text-2xl font-semibold text-black mb-4 text-center">
              Get in Touch
            </h2> */}
            <p className="text-gray-600 mb-6">
              Fill out the form below and we’ll get back to you shortly.
            </p>

            <div className="mb-4">
              <textarea
                name="message"
                cols="30"
                rows="4"
                className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                placeholder="Please enter your message here..."
                disabled={submitted}
                value={formData.message}
                onChange={(e) => handleInputChange(e)}
              />
              <p
                className={`text-sm mt-1 ${
                  errors.message ? "text-red-500" : "text-transparent"
                }`}
              >
                {errors.message}
              </p>
            </div>

            <div className="flex flex-col md:flex-row mb-4 w-full gap-3">
              <div className="flex flex-col w-full md:w-1/2">
                <input
                  name="from_name"
                  type="text"
                  className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                  placeholder="Full Name"
                  disabled={submitted}
                  value={formData.fullName}
                  onChange={(e) => handleInputChange(e)}
                />
                <p
                  className={`text-sm mt-1 ${
                    errors.fullName ? "text-red-500" : "text-transparent"
                  }`}
                >
                  {errors.fullName}
                </p>
              </div>
              <div className="flex flex-col w-full md:w-1/2">
                <input
                  name="reply_to"
                  type="email"
                  className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                  placeholder="Email Address"
                  disabled={submitted}
                  value={formData.email}
                  onChange={(e) => handleInputChange(e)}
                />
                <p
                  className={`text-sm mt-1 ${
                    errors.email ? "text-red-500" : "text-transparent"
                  }`}
                >
                  {errors.email}
                </p>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="px-6 py-3 text-lg font-semibold text-white bg-primaryMedium rounded-md hover:bg-blue-100 hover:text-black transition-colors duration-300 w-full"
                disabled={submitted}
              >
                {submitted ? "Submitting..." : "Submit"}
              </button>
              <p
                className={`${
                  response.includes("Successfully")
                    ? `text-green-500 mt-2`
                    : `text-red-500 mt-2`
                }`}
              >
                {response && response.includes("Successfully")
                  ? "Successfully submitted!"
                  : response.includes("Error")
                  ? "Error while submitting!"
                  : ""}
              </p>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
//   <section className="bg-gray-300 p-8 shadow-lg w-[100%] mx-auto rounded-tr-[4rem] rounded-tl-[4rem]">
//   <div className="relative mb-16 max-w-5xl mx-auto mt-16 md:px-4">
//   {/* Background wrapper with skew for a creative shape */}
//   <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white rounded-xl transform skew-y-3 shadow-lg" />

//   {/* Foreground content container */}
//   <div className="relative bg-white p-10 rounded-xl shadow-xl transform -skew-y-3">

//     {/* Headline */}
//     <h3 className="text-3xl md:text-4xl font-black text-center mb-6 text-blue-600">
//       Upgrade Your Legacy System—Built for Today, Ready for Tomorrow
//     </h3>

//     {/* Subheading */}
//     <p className="text-md md:text-lg text-gray-700 text-center mb-8 max-w-3xl mx-auto">
//       Why start over when your current system just needs an upgrade?
//       <br />
//       Mereb Technology enhances your existing software with:
//     </p>

//     {/* List with icons */}
//     <ul className="space-y-3 text-gray-700 max-w-2xl mx-auto mb-8">
//       {[
//         "Advanced features for modern demands",
//         "Seamless integration with your operations",
//         "Enhanced scalability and reliability",
//       ].map((item, i) => (
//         <li key={i} className="flex items-start space-x-3">
//            <svg
//             className="w-6 h-6 flex-shrink-0 text-blue-600"
//             fill="none"
//             stroke="currentColor"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth={2}
//               d="M9 12l2 2 4-4m0 0l-4-4m4 4H3"
//             />
//           </svg>
//           <span className="leading-relaxed">{item}</span>
//         </li>
//       ))}
//     </ul>

//     {/* Additional bold statement */}
//     <p className="text-center text-md md:text-lg text-gray-700 mb-8">
//       <strong>Try it FREE for One Month and See the Difference!</strong>
//       <br />
//       Modernize without disruption—upgrade with Mereb Technology.
//     </p>

//     {/* Call to Action */}
//     <div className="text-center">
//       <button
//         onClick={handleSubscription}
//         className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-500 transition duration-300"
//       >
//         Upgrade with Us Today
//       </button>
//     </div>
//   </div>
// </div>

//     {/* Who We Are Section (can keep or tweak) */}
//     <div className="text-gray-700 max-w-4xl mx-auto text-left mb-8">
//       <p className="text-lg mb-8">
//         We are a forward-thinking IT company specializing in modernizing
//         existing software for businesses in healthcare, finance, retail,
//         and beyond. Our mission is to help you cut costs, reduce downtime,
//         and improve performance—without the need to scrap what you already
//         have.
//       </p>
//       <div className="flex flex-row gap-6 flex-wrap md:justify-around">
//         {[
//           {
//             Text: "Innovative Custom Solutions",
//             Icon: (
//               <img
//                 src={lightIcon}
//                 width={25}
//                 alt="Innovative Custom Solutions"
//               />
//             ),
//           },
//           {
//             Text: "Top-Tier IT Talent",
//             Icon: (
//               <img
//                 src={topTier}
//                 width={26}
//                 alt="Top-Tier IT Talent"
//               />
//             ),
//           },
//           {
//             Text: "Client-Centric Partnerships",
//             Icon: (
//               <img
//                 src={client}
//                 width={28}
//                 alt="Client-Centric Partnerships"
//               />
//             ),
//           },
//         ].map((item, index) => (
//           <div key={index} className="flex items-center space-x-2">
//             {item.Icon}
//             <p className="text-black font-bold text-lg ">{item.Text}</p>
//           </div>
//         ))}
//       </div>
//     </div>

//     {/* Why Choose Us Section */}
//     <div className="p-8 text-center rounded-lg mt-16 mb-16 max-w-5xl mx-auto">
//       <h3 className="text-3xl font-extrabold text-center mb-8 text-primary">
//         What We Offer
//       </h3>
//       <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto">
//         Unlock the full potential of your business by upgrading your
//         existing software with Mereb Technologies. Experience tailored
//         solutions and strategic advantages that drive innovation and growth—
//         all without starting from scratch.
//       </p>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">
//         {[
//           {
//             title: "Software Modernization",
//             description: "Refactor, migrate, or optimize your legacy systems.",
//             icon: (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
//                 />
//               </svg>
//             ),
//           },
//           {
//             title: "Top Talent Access",
//             description:
//               "Scale your modernization project with specialized IT professionals.",
//             icon: (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
//                 />
//               </svg>
//             ),
//           },
//           {
//             title: "Seamless Integration",
//             description: "Advanced features ready for modern demands.",
//             icon: (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
//                 />
//               </svg>
//             ),
//           },
//           {
//             title: "Reliable Scalability",
//             description:
//               "Easily expand or adapt your legacy systems to new business needs.",
//             icon: (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
//                 />
//               </svg>
//             ),
//           },
//           {
//             title: "Accelerate Growth",
//             description:
//               "Implement modern tech (AI, IoT, cloud) on top of existing systems.",
//             icon: (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M13 10V3L4 14h7v7l9-11h-7z"
//                 />
//               </svg>
//             ),
//           },
//           {
//             title: "Security & Compliance",
//             description:
//               "Industry-standard data protection for your newly upgraded systems.",
//             icon: (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
//                 />
//               </svg>
//             ),
//           },
//         ].map((service, index) => (
//           <div
//             key={index}
//             className="mt-6 pt-8 pb-6 px-6 rounded-3xl bg-gray-100 shadow-sm flex flex-col items-center relative text-center"
//           >
//             <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
//               <div className="bg-white p-3 rounded-full shadow-md">
//                 <div className="text-blue-600">{service.icon}</div>
//               </div>
//             </div>
//             <h4 className="font-black text-xl mb-2 text-gray-800 mt-4">
//               {service.title}
//             </h4>
//             <p className="text-gray-600 text-sm">{service.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   </section>

//   {/* Meet the Team (Optional or keep for layout) */}
//   <div
//     className="py-12"
//     style={{
//       backgroundImage: `url(${bg2})`,
//     }}
//   >
//     <h3 className="text-3xl font-extrabold text-center mb-8 text-white">
//       Meet the Team Behind Your Upgrades
//     </h3>
//     <p className="text-center mt-8 max-w-3xl mx-auto text-white">
//       We believe in a hands-on approach to modernization. Our leadership
//       team is here to ensure every upgrade meets your business objectives
//       with minimal disruption.
//     </p>
//     <div className="flex justify-center space-x-8 pt-7">
//       <div className="text-center">
//         <img
//           src={neb_img}
//           alt="Nebyu Zekarias - CEO"
//           className="w-40 h-45 object-fit mx-auto mb-4"
//         />
//         <h4 className="font-semibold text-white">Nebyu Zekarias</h4>
//         <p className="text-white">CEO</p>
//       </div>
//       <div className="text-center">
//         <img
//           src={yar_img}
//           alt="Yared Taddese - COO"
//           className="w-40 h-45 object-fit mx-auto mb-4"
//         />
//         <h4 className="font-semibold text-white">Yared Taddese</h4>
//         <p className="text-white">COO</p>
//       </div>
//       <div className="text-center">
//         <img
//           src={sam_img}
//           alt="Samuel Mussie - CTO"
//           className="w-40 h-45 object-fit mx-auto mb-4"
//         />
//         <h4 className="font-semibold text-white">Samuel Mussie</h4>
//         <p className="text-white">CTO</p>
//       </div>
//     </div>
//     <div className="text-center mt-8">
//       <button
//         className="bg-gradient-to-l from-blue-400 to-blue-500 mt-2 px-7 py-1 text-white rounded-lg hover:bg-blue-500 cursor-pointer"
//       >
//         <a className="cursor-pointer" target="_blank" rel="noreferrer" href="https://wa.me/+251916144128" >
//           Book a Meeting
//         </a>
//       </button>
//     </div>
//   </div>

//   {/* Our Works Section (Examples) */}
//   <section className="bg-gray-300 p-8 shadow-lg mb-16 w-[100%] rounded-bl-[4rem] rounded-br-[4rem]">
//     <p className="text-lg text-gray-700 text-left mb-8 max-w-3xl mx-auto mt-16">
//       From poker platforms to NFT marketplaces, our solutions showcase the
//       power of custom software upgrades and seamless transformations.
//     </p>
//     <div className="rounded-xl overflow-hidden flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 justify-center">
//       {[
//         {
//           title: "Real Gaming Poker USA",
//           description:
//             "Upgraded a custom poker platform with real-time gaming features.",
//           buttonText: "View Project",
//           img: pocker,
//           link: "/Insights/poker_optimization",
//         },
//         {
//           title: "Prism NFT MarketPlace",
//           description:
//             "Enhanced an NFT marketplace for digital art and collectibles.",
//           buttonText: "View Project",
//           img: nft,
//           link: "/portfolio",
//         },
//       ].map((project, index) => (
//         <div
//           key={index}
//           className="max-w-[350px] bg-white rounded-xl overflow-hidden shadow-md"
//         >
//           <img
//             className="w-full h-48 object-cover"
//             src={project.img}
//             alt={project.title}
//           />
//           <div className="p-6">
//             <h2 className="text-blue-600 font-bold text-xl mb-2">
//               {project.title}
//             </h2>
//             <p className="text-gray-700 text-base mb-4">
//               {project.description}
//             </p>
//             <a
//               href={project.link}
//               className="text-blue-600 font-bold hover:underline"
//             >
//               {project.buttonText}
//             </a>
//           </div>
//         </div>
//       ))}
//     </div>

//     {/* Key Achievements Section */}
//     <div className="bg-blue-800 text-white mt-8 pb-16 pt-5 px-8 rounded-[4rem] shadow-lg mb-16 max-w-5xl mx-auto">
//       <h3 className="text-3xl font-[990] text-center mb-8">Key Achievements</h3>
//       <div className="flex flex-col md:flex-row items-center justify-center md:space-x-6">
//         {/* Left-Aligned Content */}
//         <div
//           className="w-[300px] bg-gradient-to-br from-white to-blue-200 text-blue-900 p-6 rounded-[2rem] shadow-md transform transition duration-300 hover:scale-105 mb-6 md:mb-0
//           flex flex-col items-start justify-center"
//         >
//           <p className="text-4xl font-extrabold mb-2">$1,251,420</p>
//           <p className="text-lg font-semibold">
//             Saved in development costs for one of our largest clients
//           </p>
//         </div>

//         {/* Right-Aligned Content */}
//         <div className="md:w-1/2 grid grid-cols-1 gap-6">
//           <div
//             className="bg-gradient-to-br from-white to-blue-200 text-blue-900 p-6 rounded-[2rem] shadow-md transform transition duration-300 hover:scale-105
//             flex flex-row items-center justify-center gap-2"
//           >
//             <p className="text-4xl font-extrabold mb-2 whitespace-nowrap">
//               1 Year
//             </p>
//             <p className="text-lg font-semibold">
//               Reduced time to launch by upgrading existing systems instead
//               of building from scratch
//             </p>
//           </div>
//           <div
//             className="bg-gradient-to-br from-white to-blue-200 text-blue-900 p-6 rounded-[2rem] shadow-md transform transition duration-300 hover:scale-105
//             flex flex-row items-center justify-center gap-1"
//           >
//             <p className="text-lg font-semibold text-right">
//               Minimized downtime with
//             </p>
//             <p className="text-4xl font-extrabold mb-2 whitespace-nowrap">
//               30 Days
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="text-center mt-8 mb-8">
//       <button onClick={() => navigate("/portfolio")}
//        className="mt-4 px-6 py-2 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300">
//       See More Success Stories
//       </button>
//     </div>
//   </section>
